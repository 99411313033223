import React from "react";
import "../../styles/chato.css";
import { useDarkMode } from "../../util/theme";
import {
  Accordion,
  AccordionSummary,
  Container,
  makeStyles,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import Section from "../Section";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Meta from "../Meta";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

const ChatPageUI = () => {
  const { value: darkMode } = useDarkMode();

  const classes = useStyles();

  const backgroundColor = darkMode ? "#0F0B29" : "#EFEFEF";
  const textColor = darkMode ? "#fff" : "#000";

  const items = [
    {
      question: "How does the AI Girlfriend Work? ",
      answer:
        "Our platform utilizes advanced AI technology to create your dream AI Girlfriend based on individual preferences and interests. Users can customize their appearance, personality traits, and interests for a personalized experience. ",
    },
    {
      question: "How do people interact with their AI girlfriends? ",
      answer:
        "We utilize a smart system and cutting-edge machine learning, through which users can interact with our AI Girlfriend through texts and receive realistic and genuine responses in return. Since our platform is free from NSFW Filters, users can effortlessly explore their desires and engage in intimate conversations with our virtual companion.",
    },
    {
      question: "Can I design my own AI Girlfriend? ",
      answer:
        "Yes, you can craft your AI Girlfriend based on your individual preferences. Figma allows users to customize their virtual companion by selecting its ethnicity, body type, facial features, personality traits, and interests.",
    },
    {
      question: "Is it a Safe Platform?",
      answer:
        "Yes, this tool is a safe and secure platform that utilizes advanced encryption to ensure 100% data protection and keep users’ conversations private and secure.",
    },
  ];

  const meta = {
    title: " #1 Best NSFW AI chat without Limit ", 
    description:
      " Explore the #1 best NSFW AI chat platform with no restrictions. Engage in limitless conversations with advanced NSFW character AI bots designed for immersive chat experiences.",
    domain: "https://www.nsfwartgenerator.ai",
  };

  return (
    <div className="home" style={{ backgroundColor, color: textColor }}>
      <Meta {...meta} />
      <div className="top-header" style={{ backgroundColor: backgroundColor }}>
        <div className="column-9">
          <div className="content-9">
            <div className="top-heading" style={{ color: textColor }}>
              <h1 className="top-heading">Best NSFW AI chat without Limit</h1>
            </div>
            <span className="top-para" style={{ color: textColor }}>
              Engage in No Filter NSFW AI chat with your dream AI Girlfriend.
              Users can engage in uncensored conversations and explore their
              wildest fantasies and desires without any limitations. With our
              NSFW AI chat app, users can easily specify the kind of discussions
              they want to indulge in with AI chatbot from playful flirting to sexting to
              emotional support.
            </span>
          </div>
          <div className="button">
            <a href="https://candy.ai?via=mlyearning&fp_sid=nsfwtool" style={{ color: "white", textDecoration: "none" }}>
              Try It Now
            </a>
          </div>
        </div>
        <div className="placeholder-image-first"></div>
      </div>

      <div className="features" style={{ backgroundColor, color: textColor }}>
        <div className="section-title">
          <div className="column">
            <span className="heading" style={{ color: textColor }}>
              Make Your Dream Girl <br />
              With The Magic of AI <br />
            </span>
          </div>
          <div className="column">
            <span className="para" style={{ color: textColor }}>
              Unleash your creativity, embrace the magic of AI, and embark on a
              journey of crafting your dream AI girlfriend! With our platform,
              you can craft your Dream AI Girlfriend that can satisfy your needs
              and expectations in just a few simple clicks.
            </span>
          </div>
        </div>
        <div className="row">
          <div className="list-item">
            <div className="vuesaxtwotonesmileys">
              <img
                alt=""
                className="smileys"
                src="assets/vectors/Smileys_x2.svg"
              />
            </div>
            <div className="heading-1" style={{ color: textColor }}>
              Sign Up on the Platform:
            </div>
            <div className="text" style={{ color: textColor }}>
              Start by visiting our official website and sign up for a free
              account by providing your email address and password.
            </div>
          </div>
          <div className="list-item-1">
            <div className="vuesaxtwotonemagicpen">
              <img
                alt=""
                className="magicpen"
                src="../../assets/vectors/Magicpen1_x2.svg"
              />
            </div>
            <div className="heading-1" style={{ color: textColor }}>
              Create Your AI Girlfriend
            </div>
            <div className="text" style={{ color: textColor }}>
              Once you have successfully signed up. Navigate to the “AI
              Girlfriend” section, tap on “Create My AI Girlfriend” and design
              your virtual companion.
            </div>
          </div>
          <div className="list-item-2">
            <div className="vuesaxtwotoneeye-slash">
              <img
                alt=""
                className="eye-slash"
                src="../../assets/vectors/EyeSlash1_x2.svg"
              />
            </div>
            <div className="heading-1" style={{ color: textColor }}>
              Customize your AI Girlfriend{" "}
            </div>
            <div className="text" style={{ color: textColor }}>
              Customize your AI Girlfriend by specifying its appearance and
              personality traits on the platform. Users choose their desired
              body type, facial features, hairstyle, clothing, personality
              traits, and background of their AI Girlfriend based on their
              individual preferences. Note the more detailed you are, the more
              personalized your AI Girlfriend will be.
            </div>
          </div>
          <div className="list-item-2">
            <div className="vuesaxtwotoneeye-slash">
              <img
                alt=""
                className="eye-slash"
                src="../../assets/vectors/EyeSlash1_x2.svg"
              />
            </div>
            <div className="heading-1" style={{ color: textColor }}>
              Start your NSFW Conversations{" "}
            </div>
            <div className="text" style={{ color: textColor }}>
              Once you have crafted your dream AI Girlfriend, users can start
              their chatting journey with our AI Girlfriend and talk about
              various topics without any restrictions.
            </div>
          </div>
        </div>
      </div>

      <div className="gallery" style={{ backgroundColor, color: textColor }}>
        <div className="section-title-1">
          <div className="heading-4" style={{ color: textColor }}>
            Our NSFW AI Girlfriend Examples <br />
          </div>
          <span className="text-4" style={{ color: textColor }}></span>
        </div>
        <div className="content-1">
          <div className="column-3">
            <div className="placeholder-image-1"></div>
            <div className="placeholder-image-2"></div>
          </div>
          <div className="column-4">
            <div className="placeholder-image-3"></div>
            <div className="placeholder-image-4"></div>
            <div className="placeholder-image-5"></div>
          </div>
          <div className="column-5">
            <div className="placeholder-image-6"></div>
            <div className="placeholder-image-7"></div>
          </div>
        </div>
        <div className="button-1">
          <a href="/#" style={{ color: "white", textDecoration: "none" }}>
            Try It Now
          </a>
        </div>
      </div>

      <div className="features-2" style={{ backgroundColor, color: textColor }}>
        <div className="column-10">
          <span className="heading-4" style={{ color: textColor }}>
            Features <br />
          </span>
        </div>

        <div className="column-12">
          <span className="heading-10" style={{ color: textColor }}>
            Unfiltered Conversation
          </span>
        </div>
        <div className="column-13">
          <div className="para-10" style={{ color: textColor }}>
            Users can create deeper connections with their desired AI virtual
            companion by engaging in No-Filter NSFW conversations. With this
            tool, users can engage in unique conversations on a wider range of
            topics such as romance, flirting, and even intimacy without any
            restrictions. You can explore your wildest fantasies, and desires,
            and even have playful conversations with your AI companion.
            <br />
          </div>
        </div>

        <div className="column-12">
          <span className="heading-10" style={{ color: textColor }}>
            Diverse AI Girlfriends Options{" "}
          </span>
        </div>
        <div className="column-13">
          <div className="para-10" style={{ color: textColor }}>
            Our platform provides a wide range of AI companion options from
            different backgrounds, appearances, and interests. Allowing users to
            choose a companion that perfectly aligns with their preferences and
            desires.
            <br />
          </div>
        </div>

        <div className="column-12">
          <span className="heading-10" style={{ color: textColor }}>
            Emotional Connection
          </span>
        </div>
        <div className="column-13">
          <div className="para-10" style={{ color: textColor }}>
            Apart from intimate and uncensored conversations, It also provides
            companionship and emotional support by engaging in personal and
            meaningful conversations. Our platform is powered by advanced AI
            technology that allows natural and engaging conversations. You can
            share your feelings, thoughts, and experiences with your AI
            Girlfriend and tailor the experience to feel more personal and
            relatable.
            <br />
          </div>
        </div>

        <div className="column-12">
          <span className="heading-10" style={{ color: textColor }}>
            100% Privacy and Security
          </span>
        </div>
        <div className="column-13">
          <div className="para-10" style={{ color: textColor }}>
            We value users privacy and security. Here we employ advanced
            encryption and secure protocols to protect users’ data and ensure
            your interactions with your virtual companion remain private.
            <br />
          </div>
        </div>

        <div className="button-1">
          <a href="/#" style={{ color: "white", textDecoration: "none" }}>
            Try It Now
          </a>
        </div>
      </div>

     <br /><br /> <br /><br />

      <div style={{ textAlign: "center" }}>
        <span className="heading-4" style={{ color: textColor }}>
          FAQs <br />
        </span>
        <Section style={{ textAlign: "left" }}>
          <Container maxWidth="md">
            {items.map((item, index) => (
              <Accordion
                classes={{
                  root: classes.accordion,
                  expanded: classes.expanded,
                }}
                key={index}
              >
                <AccordionSummary
                  classes={{
                    root: classes.summary,
                    content: classes.summaryContent,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`faq-panel-${index}`}
                >
                  <Typography variant="h6">{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails id={`faq-panel-${index}`}>
                  <Typography>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Section>
      </div>

      {/*<div className="cta" style={{ backgroundColor, color: textColor }}>
        <div className="column-6">
          <div className="content-5">
            <div className="heading-7" style={{ color: textColor }}>
              Build Your Custom <br />
              AI Girlfriend Now!
            </div>
            <div className="text-4" style={{ color: textColor }}>
             
            </div>
          </div>
          <div className="button" style={{ marginTop: "20px" }}>
            <a href="/#" style={{ color: "white", textDecoration: "none" }}>
              Try It Now
            </a>
          </div>
        </div>
        <div className="placeholder-image-last"></div>
                </div>*/}
    </div>
  );
};

export default ChatPageUI;
